import React from "react";
import {
    FaChevronRight,
    FaEnvelope,
    FaFacebookF,
    FaInstagram,
    FaMapMarkerAlt,
    FaPhoneAlt,
    FaTwitter
} from "react-icons/fa";
import {Link} from "react-router-dom";

const FooterFour = () => {
    // const submitForm = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData(e.target);
    //     const name = formData.get("name");
    //     const email = formData.get("email");
    //     console.log("Form submitted", name, email);
    // };
    return (
        <>
            {/* ================== Footer Four Start ==================*/}
            <footer
                className='footer-area bg-cover mt-0 pd-top-120'
                style={{backgroundImage: 'url("assets/img/bg/14.png")'}}
            >
                {/*<div className='footer-subscribe'>*/}
                {/*    <div className='container'>*/}
                {/*        <div className='footer-subscribe-inner box-shadow style-2 bg-white p-0 bg-cover flex-column flex-lg-row'>*/}
                {/*            <div className='media bg-base-2 justify-content-center'>*/}
                {/*                <div className='media-left me-3 align-self-center'>*/}
                {/*                    <img src='/assets/img/icon/40.svg' alt='phone'/>*/}
                {/*                </div>*/}
                {/*                <div className=''>*/}
                {/*                    <a href="tel://+660641926366" className='text-white'>+(66) 064-192-6366</a>*/}
                {/*                    <p className='mb-0 text-white'>9:00 - 17:00 <br/> Mon - Fri</p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <form onSubmit={submitForm} className="align-self-center">*/}
                {/*                <div className='row'>*/}
                {/*                    <div className='col-lg-4'>*/}
                {/*                        <div className='border-1'>*/}
                {/*                            <input type='text' name="name" placeholder='Name'/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className='col-lg-4'>*/}
                {/*                        <div className='border-1'>*/}
                {/*                            <input type='text' name="email" placeholder='Email'/>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                    <div className='col-lg-4 mt-md-4 mt-lg-0'>*/}
                {/*                        <button*/}
                {/*                            type="submit"*/}
                {/*                            className='btn w-100 btn-black sky border-radius-0'*/}
                {/*                        >*/}
                {/*                            Submit now*/}
                {/*                        </button>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </form>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <div className='widget widget-recent-post'>
                                <h4 className='widget-title'>Contact us</h4>
                                <div className='widget widget_contact'>
                                    <ul className='details text-white'>
                                        <li>
                                            <FaMapMarkerAlt className='sky'/>
                                            1999/22 The District Sriwara, Phlabphla, Wang Thonglang, Bangkok 10310
                                        </li>
                                        <li className='mt-3'>
                                            <a href="tel://+660641926366">
                                                <FaPhoneAlt className='sky'/>
                                                +(66) 064-192-6366
                                            </a>
                                        </li>
                                        <li className='mt-2'>
                                            <a href="mailto://contact@bchursoftware.com">
                                                <FaEnvelope className='sky'/>
                                                hello@bchusoftware.com
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className='social-media mt-4'>
                                        <li>
                                            <a href="https://facebook.com/bchu-softwares" className='sky'>
                                                <FaFacebookF/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://facebook.com/bchu-softwares" className='sky'>
                                                <FaTwitter/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://facebook.com/bchu-softwares" className='sky'>
                                                <FaInstagram/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 ps-xl-5'>
                            <div className='widget widget_nav_menu'>
                                <h4 className='widget-title'>Our Service</h4>
                                <ul>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Ui Design
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Web design
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Design Discovery
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Consultancy
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> 3rd Party API
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Web Development
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 ps-xl-5'>
                            <div className='widget widget_nav_menu'>
                                <h4 className='widget-title'>Explore</h4>
                                <ul>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> About
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Contact
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Careers
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Services
                                        </Link>
                                    </li>
                                    <li className='sky'>
                                        <Link to='/services'>
                                            <FaChevronRight/> Case Studies
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-bottom'>
                    <div className='container'>
                        <div className='flex flex-column flex-md-row justify-content-md-between'>
                            <div className='flex flex-column flex-md-row align-self-center align-content-md-between'>
                                <span>© Bchu Software {new Date().getFullYear()}</span>
                                <span className="d-none d-md-inline-block px-1">&nbsp;|&nbsp;</span>
                                <span>All Rights Reserved</span>
                            </div>
                            <div className='text-center text-lg-end'>
                                <Link to="/terms">Terms &amp; Conditions</Link>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    );
};

export default FooterFour;
