export const teamMembers = [
    {
        name: 'Boat',
        department: 'CEO',
        image: '/assets/img/team/2.png',
        social: [
            { name: 'facebook', link: 'https://www.facebook.com/boat' },
            { name: 'linkedin', link: 'https://www.linkedin.com/boat' },
            { name: 'twitter', link: 'https://www.twitter.com/boat' },
        ]
    },
    {
        name: 'Fa',
        department: 'SEO Department',
        image: '/assets/img/team/2.png',
        social: [
            { name: 'facebook', link: 'https://www.facebook.com/napat' },
            { name: 'linkedin', link: 'https://www.linkedin.com/napat' },
        ]
    },
    {
        name: 'Sayantan',
        department: 'Tech Department',
        image: '/assets/img/team/2.png',
        social: [
            { name: 'facebook', link: 'https://www.facebook.com/sayantan' },
            { name: 'linkedin', link: 'https://www.linkedin.com/sayantan' },
            { name: 'twitter', link: 'https://www.twitter.com/sayantan' },
        ]
    },
    {
        name: 'Yok',
        department: 'Finance Department',
        image: '/assets/img/team/2.png',
        social: [
            { name: 'linkedin', link: 'https://www.linkedin.com/yok' },
        ]
    },
]
