import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [active, setActive] = useState(false);
  // const [searchShow, setSearchShow] = useState(false);
  const menuActive = () => {
    setActive(!active);
  };
  // const searchActive = () => {
  //   setSearchShow(!searchShow);
  //   console.log("hell");
  // };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");
  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
      {/* search popup start*/}
      {/*<div*/}
      {/*  className={searchShow ? "td-search-popup active" : "td-search-popup "}*/}
      {/*  id='td-search-popup'*/}
      {/*>*/}
      {/*  <form action='/' className='search-form'>*/}
      {/*    <div className='form-group'>*/}
      {/*      <input*/}
      {/*        type='text'*/}
      {/*        className='form-control'*/}
      {/*        placeholder='Search.....'*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <button type='submit' className='submit-btn'>*/}
      {/*      <FaSearch />*/}
      {/*    </button>*/}
      {/*  </form>*/}
      {/*</div>*/}
      {/*/!* search popup end*!/*/}
      {/*<div*/}
      {/*  onClick={searchActive}*/}
      {/*  className={searchShow ? "body-overlay active" : "body-overlay"}*/}
      {/*  id='body-overlay'*/}
      {/*></div>*/}
      {/* ==================== Navbar Two Start ====================*/}
      <nav className='navbar navbar-area navbar-area-3 navbar-expand-lg nav-bg-dark'>
        <div className='container nav-container custom-container'>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <NavLink to='/'>
              <img src='/assets/img/logo3.png' alt='img' />
            </NavLink>
          </div>
          {/*<div className='nav-right-part nav-right-part-mobile align-self-center'>*/}
          {/*    <NavLink*/}
          {/*        className='btn btn-base-color border-radius-5 d-flex align-items-center'*/}
          {/*        to='/contact'*/}
          {/*    >*/}
          {/*      <span className="nav-right-part-mobile">*/}
          {/*        <FaComments className='mt-0' aria-label="contact" />*/}
          {/*      </span>*/}
          {/*      /!*<FaArrowRight className='mt-0'/>*!/*/}
          {/*    </NavLink>*/}
          {/*</div>*/}
          <div
              className={
                active
                    ? "collapse navbar-collapse sopen"
                    : "collapse navbar-collapse"
              }
              id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-center ps-lg-5 navbarFive'>
              <li>
                <NavLink to='/'>Home</NavLink>
              </li>
              <li>
                <NavLink to='/services'>Services</NavLink>
                {/*<Link to='/services/details'>Service Single</Link>*/}
              </li>

              <li>
                <NavLink to='/case-study-details'>Case Studies</NavLink>
              </li>
              <li>
                <NavLink to='/about'>About Us</NavLink>
              </li>
              <li className="d-lg-none">
                <NavLink to='/contact'>Contact Us</NavLink>
              </li>

              {/*<li className='menu-item-has-children'>*/}
              {/*  <a href="/#id">Pages</a>*/}
              {/*  <ul className='sub-menu'>*/}
              {/*    <li>*/}
              {/*      <Link to='/about'>About Us</Link>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Link to='/team'>Team</Link>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Link to='/team-details'>Team Details</Link>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Link to='/case-study-details'>Case Study Details</Link>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</li>*/}
              {/*<li className='menu-item-has-children'>*/}
              {/*  <a href="/#id">Blog</a>*/}
              {/*  <ul className='sub-menu'>*/}
              {/*    <li>*/}
              {/*      <Link to='/blog'>Blog</Link>*/}
              {/*    </li>*/}
              {/*    <li>*/}
              {/*      <Link to='/blog/details'>Blog Details</Link>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop align-self-center'>
          <NavLink
              className='btn btn-base-color border-radius-5 d-flex align-items-center'
              to='/contact'
            >
              Contact Us <FaArrowRight className='mt-0' />
            </NavLink>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
