import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
const ContactMain = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // Please See Documentation for more information
    emailjs
      .sendForm(
        "service_yipk4xg", //YOUR_SERVICE_ID
        "template_71bgc2q", //YOUR_TEMPLATE_ID
        form.current,
        "cwf8kROl5o3__96Ti" //YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Massage Sent Successfully!");
            form.current[0].value = "";
            form.current[1].value = "";
            form.current[2].value = "";
            form.current[3].value = "";
          }
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Massage Not Sent!");
          }
        }
      );
  };
  return (
    <>
      <Toaster position='bottom-center' reverseOrder={false} />
      <div className='contact-area my-5'>
        <div className='container'>
          <div className='contact-page-inner bg-gray'>
            <div className='section-title mb-4 pb-2'>
              <h2 className='title'>Direct contact us? </h2>
              <p className='content mb-0'>
                For your car we will do everything advice, repairs and
                maintenance. We are the some preferred choice by many car owners
                because our experience and knowledge is selfe vident.For your
                car we will do som everything.
              </p>
            </div>
            <form ref={form} onSubmit={sendEmail}>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <label className='input-label'>Full Name:
                      <input
                          id='name'
                          name='user_name'
                          type='text'
                          placeholder='Enter Your Name'
                          required
                      />
                    </label>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <label className='input-label'>Email:
                      <input
                          id='email'
                          name='user_email'
                          type='email'
                          placeholder='Enter Your Email'
                          required
                      />
                    </label>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <label className='input-label'>Phone:
                      <input
                          id='phone'
                          name='phone'
                          type='text'
                          placeholder='Enter Your Phone Number'
                          required
                      />
                    </label>
                  </div>
                </div>
                <div className='col-md-12'>
                  <div className='single-input-inner'>
                    <label className='input-label'>Company Name:
                      <input
                          id='company'
                          name='company'
                          type='text'
                          placeholder='Enter Your Company Name'
                          required
                      />
                    </label>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='single-input-inner'>
                    <label className='input-label'>Message:
                      <textarea
                          name='message'
                          id='massage'
                          cols='1'
                          rows='5'
                          placeholder='Enter Your Massage ...'
                          required
                      />
                    </label>
                  </div>
                </div>
                <div className='col-12 text-center'>
                  <button
                      className='btn btn-base border-radius-5'
                      type='submit'
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* contact list */}
      <div className='contact-page-list'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='/assets/img/icon/13.svg' alt='img'/>
                </div>
                <div className='media-body'>
                  <h5>Contacts Us</h5>
                  <a href='tel://+660641926366'>+(66) 064-192-6366</a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='/assets/img/icon/14.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Email Us</h5>
                  <a href='mailto://contact@bchusoftware.com'>contact@bchusoftware.com</a>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='media single-contact-list'>
                <div className='media-left'>
                  <img src='/assets/img/icon/15.svg' alt='img' />
                </div>
                <div className='media-body'>
                  <h5>Location</h5>
                  <p className='overflow-ellipsis mb-0' style={{ maxWidth: 200 }}>
                    1999/22 The District Sriwara, Phlabphla, Wang Thonglang, Bangkok 10310
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactMain;
