import React from "react";

const WorkProcessFour = () => {
  return (
    <>
      {/*==================== Work Process Three start ====================*/}
      <div
        className='work-process-area bg-position-right pd-top-120 pd-bottom-90'
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center pb-5 mb-0'>
                <h6 className='sub-title-sky-blue text-uppercase mt-5'>Work Process</h6>
                <h2 className='title'>
                  Empowering Your Business with Innovative Solutions
                </h2>
              </div>
            </div>
          </div>
          <div className='work-process-area-inner-2'>
            <div className='row'>
              <div className='col-lg-3 col-md-6'>
                <div className='single-work-process-inner style-2 text-center'>
                  <div className='thumb mb-3'>
                    <img src='/assets/img/icon/36.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Assessment</h5>
                    <p className='content'>
                      We start by thoroughly assessing your business needs, understanding your goals, and identifying
                      opportunities for improvement. This phase lays the foundation for a tailored solution that meets
                      your unique requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='single-work-process-inner style-2 text-center'>
                  <div className='thumb mb-3'>
                    <img src='/assets/img/icon/37.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Implementation Planning</h5>
                    <p className='content'>
                      Once we’ve gathered insights, our team develops a comprehensive implementation plan. We create a
                      roadmap that outlines every step, ensuring smooth execution and alignment with your business objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='single-work-process-inner style-2 text-center'>
                  <div className='thumb mb-3'>
                    <img src='/assets/img/icon/38.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Capabilities Development</h5>
                    <p className='content'>
                      Our expert developers bring the plan to life by building a robust, scalable, and efficient
                      solution. We leverage the latest technologies to ensure your software is not only functional but
                      future-proof.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='single-work-process-inner style-2 text-center'>
                  <div className='thumb mb-3'>
                    <img src='/assets/img/icon/39.svg' alt='img' />
                  </div>
                  <div className='details'>
                    <h5 className='mb-3'>Innovation</h5>
                    <p className='content'>
                      Innovation is at the core of everything we do. We continuously refine and improve, ensuring that
                      your business stays ahead of the curve in a rapidly evolving digital landscape.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ==================== Work Process Three end ====================*/}
    </>
  );
};

export default WorkProcessFour;
