import React from "react";

const AboutAreaSix = () => {
  return (
    <>
      <div className='about-area about-area-margin-bottom pd-top-120'>
        <div className='container'>
          <div className='box-shadow bg-white z-index-2 border-radius-5 p-xl-5 p-4'>
            <div className='row'>
              <div className='col-lg-6'>
                <div
                  className='about-thumb-inner pe-xl-5 pb-5 me-xl-4'
                  data-aos='fade-right'
                  data-aos-delay='200'
                  data-aos-duration='1500'
                >
                  <img
                    className='animate-img-4 top_image_bounce z-index-2 d-none d-md-block'
                    src='/assets/img/about/board.webp'
                    alt='scribbles on board'
                  />
                  <img
                    className='main-img'
                    src='/assets/img/about/discussion.webp'
                    alt='team discussing in a meeting'
                  />
                </div>
              </div>
              <div
                className='col-lg-6'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mt-5 mt-lg-0 mb-0'>
                  <h6 className='sub-title-sky-blue text-uppercase'>Why Choose Us</h6>
                  <h2 className='title'>Driving Innovation with Smart Solutions</h2>
                  <p className='content'>
                    Every business is unique, and so are our solutions. We craft software that perfectly fits your
                    business requirements and long-term vision.
                  </p>
                  <p className='content'>
                    We stay at the forefront of technology, using the latest frameworks and tools to ensure
                    your software is future-ready and capable of evolving with your business.
                  </p>
                  <div className='row mt-4'>
                    <div className='col-md-4'>
                      <div className='image-hover-rotate border-1 p-4 border-radius-5 text-center'>
                        <div className='thumb mb-3'>
                          <img
                              className='rotate-img'
                              src='/assets/img/about/25.png'
                              alt='img'
                          />
                        </div>
                        <div className='details'>
                          <h6 className='mb-0'>Personalized Solutions</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='image-hover-rotate border-1 p-4 border-radius-5 text-center'>
                        <div className='thumb mb-3'>
                          <img
                              className='rotate-img'
                              src='/assets/img/about/26.png'
                              alt='img'
                          />
                        </div>
                        <div className='details'>
                          <h6 className='mb-0'>Innovative Technology</h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='image-hover-rotate border-1 p-4 border-radius-5 text-center'>
                        <div className='thumb mb-3'>
                          <img
                              className='rotate-img'
                              src='/assets/img/about/27.png'
                              alt='img'
                          />
                        </div>
                        <div className='details'>
                          <h6 className='mb-0'>Business Focused Approach</h6>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutAreaSix;
