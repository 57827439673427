import React from "react";
import AboutAreaOne from "../components/AboutAreaOne";
import Breadcrumb from "../components/Breadcrumb";
import ContactAreaOne from "../components/ContactAreaOne";
import CounterAreaOne from "../components/CounterAreaOne";
import FaqAreaOne from "../components/FaqAreaOne";
import TeamAreaOne from "../components/TeamAreaOne";
import WorkProcessOne from "../components/WorkProcessOne";
import WhyBchu from "../components/WhyBchu";

const About = () => {
    return (
        <>
            <Breadcrumb title={"About Us"}/>

            <AboutAreaOne/>

            <CounterAreaOne/>

            <TeamAreaOne />

            <WorkProcessOne/>

            <FaqAreaOne/>

            <WhyBchu />

            <ContactAreaOne/>

        </>
    );
};

export default About;
