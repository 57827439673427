import React from "react";
import { FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const AboutAreaFive = () => {
  return (
    <>
      {/* =============== About Area Five End ===============*/}
      <div className='about-area pd-top-90 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 mb-4 mb-lg-0'
              data-aos='fade-right'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner'>
                <img
                  className='main-img'
                  src='/assets/img/about/smart.webp'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6'
              data-aos='fade-left'
              data-aos-delay='200'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 ps-xl-5'>
                <h6 className='sub-title-sky-blue text-uppercase'>About Company</h6>
                <h2 className='title'>
                  Experience intelligence like never before
                </h2>
                <p className='content mb-4'>
                  At Bchu Software, we are committed to delivering innovative and scalable web solutions that are crafted
                  to meet the unique needs of your business. Whether you aim to optimize internal processes, enhance customer
                  interaction, or develop a secure and robust platform, our team ensures that each solution is aligned
                  with your objectives, providing measurable results.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle className='sky' /> Product Strategy
                      </li>
                      <li>
                        <FaCheckCircle className='sky' /> Product Requirements
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle className='sky' /> Technical Planning
                      </li>
                      <li>
                        <FaCheckCircle className='sky' /> Marketing Strategy
                      </li>
                    </ul>
                  </div>
                </div>
                <Link
                  className='btn btn-base-color border-radius-5'
                  to='/about'
                >
                  Discover More <FaArrowRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Five End ===============*/}
    </>
  );
};

export default AboutAreaFive;
