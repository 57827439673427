import React from "react";
import { Link } from "react-router-dom";

const ServiceAreaFive = () => {
  return (
    <>
      <div className='service-area bg-gray bg-relative pd-top-90 pd-bottom-90'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6'>
              <div className='section-title text-center'>
                <h6 className='sub-title-sky-blue'>Our Best Service</h6>
                <h2 className='title'>
                  Unlocking The Potential Of Your Business
                </h2>
              </div>
            </div>
          </div>
          <div className='row custom-no-gutter'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='/assets/img/service-icon/7.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/services/details'>Custom Web Development</Link>
                  </h5>
                  <p className='mb-0'>
                    Our team builds bespoke web applications that streamline operations and are built with the
                    latest technologies to ensure seamless performance and adaptability.
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='/assets/img/service-icon/8.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/services/details'>E-Commerce Solutions</Link>
                  </h5>
                  <p className='mb-0'>
                    We create secure, scalable online stores, ensuring a smooth customer experience while
                    helping your business grow in the digital marketplace.
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-left mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='/assets/img/service-icon/9.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/services/details'>Enterprise Software Development</Link>
                  </h5>
                  <p className='mb-0'>
                    We develop powerful enterprise solutions that integrate seamlessly with your existing workflows
                    and scale as your business grows.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 bg-blue-right d-lg-inline-block d-none'>
              <div className='service-thumb service-middle-section flex-column justify-content-center'>
                <img src='/assets/img/about/surface.webp' alt='A guy using Surface laptop' />
              </div>
            </div>
            <div className='col-lg-4 col-md-6 mt-4 mt-md-0'>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='/assets/img/service-icon/10.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/services/details'>API Development & Integration</Link>
                  </h5>
                  <p className='mb-0'>
                    We enhance your applications' functionality and connectivity through reliable API development
                    and integration with third-party services.
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/11.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/services/details'>UI/UX Design</Link>
                  </h5>
                  <p className='mb-0'>
                    Our design experts create intuitive and engaging user experiences, ensuring that your platform is
                    not only visually appealing but also easy to navigate.
                  </p>
                </div>
              </div>
              <div className='single-service-inner-3 single-service-inner-3-right mb-0'>
                <div className='thumb'>
                  <div className='thumb-inner'>
                    <img src='assets/img/service-icon/12.svg' alt='img' />
                  </div>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>
                    <Link to='/services/details'>Digital Advisory</Link>
                  </h5>
                  <p className='mb-0'>
                    Transforming ideas into digital reality requires expert guidance. Our digital advisory service
                    offers strategic support throughout the product lifecycle, helping you create a success-driven roadmap.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceAreaFive;
