let serviceList = [
    {
        title: "Business Intelligence",
        des: "Empower your business with data-driven insights through our business intelligence services. We help you transform raw data into actionable insights to drive growth and efficiency.",
        img: "assets/img/service-icon/1.png",
    },
    {
        title: "Custom Software Development",
        des: "We craft tailor-made software solutions that fit your unique business requirements, ensuring scalability, performance, and long-term success.",
        img: "assets/img/service-icon/2.png",
    },
    {
        title: "ERP Solutions",
        des: "Streamline your business processes with our comprehensive ERP solutions, designed to integrate all facets of your operations into one seamless system.",
        img: "assets/img/service-icon/3.png",
    },
    {
        title: "Mobile App Development",
        des: "Develop intuitive and user-friendly mobile applications that enhance customer experience and engage users on the go, across platforms and devices.",
        img: "assets/img/service-icon/1.png",
    },
    {
        title: "E-commerce Solutions",
        des: "Grow your online business with our powerful e-commerce solutions, offering secure payment systems, scalable platforms, and seamless user experiences.",
        img: "assets/img/service-icon/3.png",
    },
    {
        title: "System Integration",
        des: "Our system integration services ensure smooth and efficient collaboration between different software systems, improving overall productivity and reducing redundancies.",
        img: "assets/img/service-icon/3.png",
    },
    {
        title: "Web Development",
        des: "We build high-performance, secure, and responsive websites that deliver an exceptional user experience, helping you make a strong online presence.",
        img: "assets/img/service-icon/3.png",
    },
    {
        title: "UX/UI Design",
        des: "Our UX/UI design services focus on creating visually appealing, intuitive, and user-centric designs. We ensure that every interaction with your product is seamless, enhancing user satisfaction and engagement.",
        img: "assets/img/service-icon/2.png",
    },
    {
        title: "Content Marketing",
        des: "Boost your brand's online presence and drive engagement with our content marketing strategies that create meaningful connections with your audience.",
        img: "assets/img/service-icon/2.png",
    },
];

export default serviceList;
