import React from "react";
import {
    SiNodedotjs,
    SiGo,
    SiTypescript,
    SiReact,
    SiNextdotjs,
    SiApachejmeter,
    SiCypress,
    SiDocker,
    SiKubernetes,
    SiTerraform,
    SiMicrosoftazure,
} from "react-icons/si";
import { FaAws } from "react-icons/fa";

const TechStack = () => {
    return (
        <div className="tech-stack">
            <div className="container py-5">
                <div className='section-title text-center'>
                    <p className='sub-title text-uppercase'>Tech Stack</p>
                    <h2 className='title'>
                        Our <span>Tech Stack</span>
                    </h2>
                </div>
                <p className="text-center">
                    We leverage a cutting-edge tech stack to deliver scalable, secure, and high-performance solutions.
                    From backend services to frontend interfaces, we use the best tools and frameworks to bring your
                    digital vision to life.
                </p>
                <div className="row text-center mt-5">
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiNodedotjs size={50} className="card-img-top"/>
                            <p className="card-text">Node.js</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiGo size={50} className="card-img-top"/>
                            <p className="card-text">Golang</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiTypescript size={50} className="card-img-top"/>
                            <p className="card-text">TypeScript</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiReact size={50} className="card-img-top"/>
                            <p className="card-text">React</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiNextdotjs size={50} className="card-img-top"/>
                            <p className="card-text">NextJS</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiApachejmeter size={50} className="card-img-top"/>
                            <p className="card-text">JMeter</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiCypress size={50} className="card-img-top"/>
                            <p className="card-text">Cypress</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiDocker size={50} className="card-img-top"/>
                            <p className="card-text">Docker</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiKubernetes size={50} className="card-img-top"/>
                            <p className="card-text">Kubernetes</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiTerraform size={50} className="card-img-top"/>
                            <p className="card-text">Terraform</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <SiMicrosoftazure size={50} className="card-img-top"/>
                            <p className="card-text">Azure</p>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-lg-3 mb-4">
                        <div className="card mt-2">
                            <FaAws size={50} className="card-img-top"/>
                            <p className="card-text">AWS</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechStack;
