import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import TeamAreaGroup from "../components/TeamAreaGroup";

const Team = () => {
  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb title={"Team"} />

      {/* Team Area Group */}
      <TeamAreaGroup />
    </>
  );
};

export default Team;
