import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import TeamDetailsGroup from "../components/TeamDetailsGroup";

const TeamDetails = () => {
  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb title={"Team Details"} />

      {/* Team Details Group */}
      <TeamDetailsGroup />
    </>
  );
};

export default TeamDetails;
