import React from "react";

const TestimonialFive = () => {
  return (
    <>
      <div
        className='testimonial-area bg-left-50 pd-top-90 pd-bottom-90'
      >
        <div className='row'>
          <div className='col-xl-5 col-lg-7 offset-xl-5 offset-lg-4'>
            <div className='bg-white box-shadow border-radius-5 p-xl-5 p-4'>
              <div className='section-title text-center text-lg-start'>
                <h6 className='sub-title-sky-blue text-uppercase'>Testimonial</h6>
                <h2 className='title'>
                  Trusted by over 100 <br /> clients Worldwide
                </h2>
              </div>
              <ul
                className='nav testimonial-tab nav-tabs justify-content-around'
                id='myTab'
                role='tablist'
              >
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link active'
                    id='company1-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#company1'
                    type='button'
                    role='tab'
                    aria-controls='company1'
                    aria-selected='false'
                  >
                    <img src='/assets/img/testimonial/fauna-solution.svg' alt='img' />
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='company2-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#company2'
                    type='button'
                    role='tab'
                    aria-controls='company2'
                    aria-selected='false'
                  >
                    <img src='/assets/img/testimonial/woo-tender.svg' alt='img' />
                  </button>
                </li>
                <li className='nav-item' role='presentation'>
                  <button
                    className='nav-link'
                    id='company3-tab'
                    data-bs-toggle='tab'
                    data-bs-target='#company3'
                    type='button'
                    role='tab'
                    aria-controls='company3'
                    aria-selected='false'
                  >
                    <img src='/assets/img/testimonial/chu-choice.svg' alt='img' />
                  </button>
                </li>
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div
                  className='tab-pane fade show active'
                  id='company1'
                  role='tabpanel'
                  tabIndex={0}
                  aria-labelledby='company1-tab'
                >
                  <h4 className='pt-xl-5 pt-4'>Robert Downly</h4>
                  <p>Senior Manager, Fauna Solutions</p>
                  <p className='pb-0 border-top-1 pt-3 mt-3'>
                    Bchu Software transformed our online operations, delivering a seamless, responsive platform that has
                    greatly improved customer satisfaction. Their expertise and commitment were top-notch and I would
                    love to work with them again.
                  </p>
                </div>
                <div
                  className='tab-pane fade'
                  id='company2'
                  role='tabpanel'
                  tabIndex={0}
                  aria-labelledby='company2-tab'
                >
                  <h4 className='pt-xl-5 pt-4'>Alex Kim</h4>
                  <p>Senior Manager, WooTender Pvt. Ltd.</p>
                  <p className='pb-0 border-top-1 pt-3 mt-3'>
                    Working with Bchu Software has been a game-changer for our business. Their custom web solution
                    helped us streamline our operations and significantly improve our efficiency. The team's expertise
                    and dedication exceeded our expectations.
                  </p>
                </div>
                <div
                  className='tab-pane fade'
                  id='company3'
                  role='tabpanel'
                  tabIndex={0}
                  aria-labelledby='company3-tab'
                >
                  <h4 className='pt-xl-5 pt-4'>Yan Lau</h4>
                  <p>Senior Designer, ChuChoice Corp.</p>
                  <p className='pb-0 border-top-1 pt-3 mt-3'>
                    Bchu Software delivered exactly what we needed—an intuitive, user-friendly platform that our
                    customers love. Their ability to understand our business goals and turn them into a digital solution
                    was remarkable.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialFive;
