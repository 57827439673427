import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import CaseStudyArea from "../components/CaseStudyArea";

const CaseStudyDetails = () => {
  return (
    <>
      <Breadcrumb title={"Case Study Details"} />

      {/* Case Study Area */}
      <CaseStudyArea />
    </>
  );
};

export default CaseStudyDetails;
