import React from "react";
import {FaCloud, FaCode, FaPaintBrush, FaFileAlt } from "react-icons/fa";

const WorkProcessOne = () => {
  return (
    <>
      <div className='work-process-area my-5 py-5'>
        <div className='container'>
          <div className='section-title text-center'>
            <p className='sub-title text-uppercase'>Our Simple Process</p>
            <h2 className='title'>
              A Streamlined Approach to Building <span>Your Digital Solutions</span>
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <FaFileAlt color="#236BFD" fontSize='3rem'/>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Software Research</h5>
                  <p className='content'>
                    We thoroughly analyze your business needs, conduct extensive market research, and identify the best
                    technologies to build the foundation for your project.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <FaPaintBrush color="#236BFD" fontSize='3rem'/>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Design and Planning</h5>
                  <p className='content'>
                    We architect a robust software structure and design user-friendly interfaces, creating a blueprint
                    for your solution's development.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <FaCode color="#236BFD" fontSize='3rem'/>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Development and Testing</h5>
                  <p className='content'>
                    Our developers bring the solution to life, writing clean, efficient code and rigorously testing it
                    for performance, security, and scalability.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <FaCloud color="#236BFD" fontSize='3rem'/>
                </div>
                <div className='details'>
                  <h5 className='mb-3'>Deploy and Scale</h5>
                  <p className='content'>
                    After deployment, we ensure a smooth rollout and provide ongoing support, scaling your solution as
                    your business grows.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
