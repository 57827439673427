import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ServiceAreaGroup from "../components/ServiceAreaGroup";
import CounterAreaOne from "../components/CounterAreaOne";
import WhyBchu from "../components/WhyBchu";
import ContactAreaOne from "../components/ContactAreaOne";
import TechStack from "../components/TechStack";

const Service = () => {
    return (
        <>
            <Breadcrumb title={"Services"}/>

            <ServiceAreaGroup/>

            <CounterAreaOne/>

            <WhyBchu />

            <TechStack />

            <ContactAreaOne />

        </>
    );
};

export default Service;
