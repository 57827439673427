import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import ContactMain from "../components/ContactMain";

const Contact = () => {
  return (
    <>
      <Breadcrumb title={"Contact"} />

      <ContactMain />
    </>
  );
};

export default Contact;
