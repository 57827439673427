import React from "react";
import TrackVisibility from "react-on-screen";
import { FaUserTie, FaRegStar, FaGlobeAsia, FaCogs } from 'react-icons/fa'

const CounterAreaOne = () => {
  return (
    <>
      <div
        className='counter-area bg-relative bg-cover mt-5'
        style={{ backgroundImage: 'url("/assets/img/bg/5.png")' }}
      >
        <div className='container'>
          <div className='row '>
            <div
                className='col-lg-3 col-sm-6 text-center'
                data-aos='fade-up'
                data-aos-delay='100'
                data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
                <div className='thumb'>
                  <FaUserTie color="white" fontSize='3rem'/>
                </div>
                <div className='text-white mt-4 mb-2 bold fs-1 fw-bold'>
                  <TrackVisibility once>
                    {({isVisible}) =>
                        isVisible && (
                            <span className='counter'>
                          75+
                        </span>
                        )
                    }
                  </TrackVisibility>
                </div>
                <p className='text-white'>Team Members</p>
              </div>
            </div>
            <div
                className='col-lg-3 col-sm-6 text-center'
                data-aos='fade-up'
                data-aos-delay='200'
                data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
                <div className='thumb'>
                  <FaCogs color="white" fontSize='3rem'/>
                </div>
                <p className='text-white mt-4 mb-2 fs-1 fw-bold'>
                  <TrackVisibility once>
                    {({isVisible}) =>
                        isVisible && (
                            <span className='counter'>
                          100+
                        </span>
                        )
                    }
                  </TrackVisibility>
                </p>
                <p className='text-white'>Successful Projects</p>
              </div>
            </div>
            <div
                className='col-lg-3 col-sm-6 text-center'
                data-aos='fade-up'
                data-aos-delay='150'
                data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
                <div className='thumb'>
                  <FaRegStar color="white" fontSize='3rem'/>
                </div>
                <p className='text-white mt-4 mb-2 fs-1 fw-bold'>
                  <TrackVisibility once>
                    {({isVisible}) =>
                        isVisible && (
                          <span className='counter'>
                            10+
                          </span>
                        )
                    }
                  </TrackVisibility>
                </p>
                <p className='text-white'>Years of Experience</p>
              </div>
            </div>
            <div
                className='col-lg-3 col-sm-6 text-center '
                data-aos='fade-up'
                data-aos-delay='250'
                data-aos-duration='1500'
            >
              <div className='single-counter-inner'>
                <div className='thumb'>
                  <FaGlobeAsia color="white" fontSize='3rem'/>
                </div>
                <p className='text-white mt-4 mb-2 fs-1 fw-bold'>
                  <TrackVisibility once>
                    {({isVisible}) =>
                        isVisible && (
                            <span className='counter'>
                          10+
                        </span>
                        )
                    }
                  </TrackVisibility>
                </p>
                <p className='text-white'>Countries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CounterAreaOne;
