import React from "react";

const FaqAreaOne = () => {
  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div
        className='faq-area faq-area-margin-top bg-cover py-5'
        style={{ backgroundImage: 'url("/assets/img/bg/3.png")' }}
      >
        <div className='container'>
          <div className='row'>
            <div
              className='col-xl-5 col-lg-6 col-md-8 order-lg-last'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner pt-lg-3'>
                <img
                  className='main-img'
                  src='/assets/img/about/question.webp'
                  alt='Asking question'
                />
                <img
                  className='animate-img-bottom-right top_image_bounce'
                  src='/assets/img/about/5.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-xl-7 col-lg-6'
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h6 className='sub-title text-uppercase'>Some FAQ's</h6>
                <h2 className='title'>
                  Frequently Asked Questions
                </h2>
                <p className='content'>
                  Find answers to common questions about our services, approach, and how we deliver tailored software
                  solutions that meet your business needs. Still have questions? Reach out to us for more information.
                </p>
              </div>
              <div
                  className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                  id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                        className='accordion-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseOne'
                        aria-expanded='true'
                        aria-controls='collapseOne'
                    >
                      What services do you offer?
                    </button>
                  </h2>
                  <div
                      id='collapseOne'
                      className='accordion-collapse collapse show'
                      aria-labelledby='headingOne'
                      data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      At Bchu Software, we specialize in building custom web applications tailored to meet the unique
                      needs of your business. Our services include full-stack web development, cloud integration,
                      software consulting, and ongoing technical support.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseTwo'
                        aria-expanded='false'
                        aria-controls='collapseTwo'
                    >
                      How does Bchu Software ensure project success?
                    </button>
                  </h2>
                  <div
                      id='collapseTwo'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingTwo'
                      data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      We take a client-centric approach to every project, beginning with in-depth research and analysis
                      of your business requirements. Throughout the development process, we maintain close collaboration
                      with you to ensure the final product meets your expectations.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseThree'
                        aria-expanded='false'
                        aria-controls='collapseThree'
                    >
                      What industries does Bchu Software serve?
                    </button>
                  </h2>
                  <div
                      id='collapseThree'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingThree'
                      data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      Bchu Software works with businesses across a wide range of industries, including e-commerce,
                      finance, healthcare, and education. Our team is experienced in developing software solutions
                      tailored to the specific needs and challenges of various sectors.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
                    <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseFour'
                        aria-expanded='false'
                        aria-controls='collapseFour'
                    >
                      How long does it take to complete a project?
                    </button>
                  </h2>
                  <div
                      id='collapseFour'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingFour'
                      data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      The timeline for each project varies depending on its scope and complexity. After an initial assessment,
                      we provide a detailed project plan, outlining milestones and deadlines to ensure timely delivery.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFive'>
                    <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#collapseFive'
                        aria-expanded='false'
                        aria-controls='collapseFive'
                    >
                      What sets Bchu Software apart from other development companies?
                    </button>
                  </h2>
                  <div
                      id='collapseFive'
                      className='accordion-collapse collapse'
                      aria-labelledby='headingFive'
                      data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                      What makes us unique is our dedication to innovation and personalized service. We stay on the
                      cutting edge of technology to deliver scalable, future-proof solutions, all while working closely
                      with clients to align with their business goals and vision.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqAreaOne;
