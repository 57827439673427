import { Link } from "react-router-dom";

const FourOhFour = () => {
    return (
        <section className="container text-center my-5 py-5">
            <p>404 Error!</p>
            <h1 className="title">Page not found!</h1>
            <h2>Sorry, the page you’re looking for does not exist.</h2>
            <Link to='/' className="btn btn-primary mt-5">Go Back to HomePage</Link>
        </section>
    );
}

export default FourOhFour;
