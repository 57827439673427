import React from "react";
import BlogGroup from "../components/BlogGroup";
import Breadcrumb from "../components/Breadcrumb";

const Blog = () => {
  return (
    <>
      <Breadcrumb title={"Blog"} />

      {/* Blog Group */}
      <BlogGroup />
    </>
  );
};

export default Blog;
