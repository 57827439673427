import React from "react";

const WhyBchu = () => {
    return (
        <section className="why-choose-bchu py-5">
            <div className="container">
                <div className='section-title mb-0 mt-4 mt-lg-0'>
                    <h6 className='sub-title text-uppercase'>Why Bchu</h6>
                    <h2 className='title'>
                        Why <span>Choose</span> Bchu Software?
                    </h2>
                    <p className='content'>
                        At Bchu Software, we combine industry expertise with innovative technologies to deliver
                        tailored software solutions that fit your unique business needs. Our client-centric approach
                        ensures that your success remains our top priority, guiding you every step of the way, from
                        concept to deployment. Trust us to build scalable, future-proof applications that empower your
                        business to thrive in the digital age.
                    </p>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 mb-4">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Expertise</h5>
                                <p className="card-text">
                                    Our team brings years of experience in building high-performance web applications.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Innovation</h5>
                                <p className="card-text">
                                    We stay on the cutting edge of technology to deliver solutions that are
                                    future-proof.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Tailored Solutions</h5>
                                <p className="card-text">
                                    Every business is unique, and so are our software solutions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card shadow-sm">
                            <div className="card-body">
                                <h5 className="card-title">Client-Centric</h5>
                                <p className="card-text">
                                    Your success is our priority, and we work closely with you every step of the way.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyBchu;
