import React from "react";
import AboutAreaFive from "../components/AboutAreaFive";
import AboutAreaSix from "../components/AboutAreaSix";
import BannerFive from "../components/BannerFive";
// import BlogAreaFive from "../components/BlogAreaFive";
import CaseStudyAreaTwo from "../components/CaseStudyAreaTwo";
import ServiceAreaFive from "../components/ServiceAreaFive";
import TeamAreaThree from "../components/TeamAreaThree";
import TestimonialFive from "../components/TestimonialFive";
import WorkProcessFour from "../components/WorkProcessFour";

const Home = () => {
    return (<>
            {/* Banner Five */}
            <BannerFive/>

            {/* About Area Five */}
            <AboutAreaFive/>

            {/* Service Area Five */}
            <ServiceAreaFive/>

            {/* Case Study Area Two */}
            <CaseStudyAreaTwo/>

            {/* About Area Six */}
            <AboutAreaSix/>

            {/* Work Process Four */}
            <WorkProcessFour/>

            {/* Testimonial Five */}
            <TestimonialFive/>

            {/* Team Area Three */}
            <TeamAreaThree/>

            {/*/!* Blog Area Five *!/*/}
            {/*<BlogAreaFive />*/}
        </>);
};

export default Home;
