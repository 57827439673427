import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import CaseStudyDetails from "./pages/CaseStudyDetails";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Team from "./pages/Team";
import TeamDetails from "./pages/TeamDetails";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import FooterFour from "./components/FooterFour";
import FourOhFour from "./components/FourOhFour";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/blog' element={<Blog />} />
        <Route path='/blog/:slug' element={<BlogDetails />} />
        <Route exact path='/case-study-details' element={<CaseStudyDetails />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/services' element={<Service />} />
        <Route exact path='/services/details' element={<ServiceDetails />} />
        <Route exact path='/team' element={<Team />} />
        <Route exact path='/team-details' element={<TeamDetails />} />
        <Route path="*" element={<FourOhFour />} />
      </Routes>
      <FooterFour />
    </BrowserRouter>
  );
}

export default App;
