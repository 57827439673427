import React from "react";
import {
  FaAngleLeft,
  FaAngleRight,
  FaFacebookF,
  FaTwitter,
  FaLinkedin
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { teamMembers } from "../scripts/teamMembers";

const SocialChannels = ({ social }) => {
  return <>{social.map(({name, link}) => (<li key={link}>
    <a href={link} target='_blank' rel='noreferrer'>
      {name === 'facebook' && <FaFacebookF className='text-white'/>}
      {name === 'linkedin' && <FaLinkedin className='text-white'/>}
      {name === 'twitter' && <FaTwitter className='text-white'/>}
    </a>
  </li>))}</>
}

const TeamMember = ({ name, social, image, department }) => {
  return (
      <div className='item px-3'>
        <div className='single-team-inner text-center border-radius-5 bg-white'>
          <div className='thumb bg-gray border-radius-5'>
            <img src={image} alt={`${name}-portrait`}/>
            <ul className='team-social-inner border-radius-5 sky'>
              <SocialChannels social={social}/>
            </ul>
          </div>
          <div className='details'>
            <h5>
              <Link to='/team-details'>{name}</Link>
            </h5>
            <p>{department}</p>
          </div>
        </div>
      </div>
  );
};

const TeamAreaThree = () => {
  function SampleNextArrow(props) {
    const {className, onClick} = props;
    return <FaAngleRight className={className} onClick={onClick}/>;
  }

  function SamplePrevArrow(props) {
    const {className, onClick} = props;
    return <FaAngleLeft className={className} onClick={onClick}/>;
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {/*====================== team area start ======================*/}
      <div
        className='team-area bg-relative pd-top-90 pd-bottom-90'
        style={{ backgroundImage: 'url("/assets/img/bg/15.png")' }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'>
              <div className='section-title style-white'>
                <h6 className='sub-title-sky-blue text-uppercase'>Meet Our Experts</h6>
                <h2 className='title'>Engineering Your Next Digital Product</h2>
              </div>
            </div>
          </div>
          <div className='team-slider owl-carousel slider-control-square slider-control-right-top'>
            <Slider {...settings}>
              {teamMembers.map(({name, social, image, department}) => (
                <TeamMember
                    name={name}
                    social={social}
                    image={image}
                    department={department}
                    key={name}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamAreaThree;
